import './App.css';

function App() {
  return (
    <div className="container mx-auto text-center m-40">
      <header>
        <h1 className="lowercase font-semibold text-7xl sm:text-8xl tracking-tight text-teal-800">Skeedio<span className="font-light text-5xl">.nl</span></h1>
        <h2 className="lowercase font-thin text-3xl sm:text-4xl tracking-widest text-teal-700/80">Webapplicaties</h2>
      </header>
      <p className="mt-8 font-thin text-md tracking-widest text-teal-800">KvK 14104808</p>
    </div>
  );
}

export default App;
